jQuery(document).ready(function($) {
	/* Mobile menu */
	$(".mobile-navigation-menu").mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	$(".mobile-menu-button").click(function() {
		if ($( ".mobile-navigation-menu" ).hasClass( "mm-opened" )) {
			$(".mobile-navigation-menu").data("mmenu").close();
		}
		else {
			$(".mobile-navigation-menu").data("mmenu").open();
		}
	});

	/* FitVids */
	if ($("body").innerWidth() <= 767) {
		$(".main-content-wrapper").fitVids({ ignore: "nofit" });
	}

	/* Scroll to top */
	$("#topLink").click(function() {
		$('html,body').animate({
			scrollTop: $("#Top").offset().top
		},
		900
		);
	});

	/* Remove nderline fomr link images */
	$('a img').parent().css("border", "0");

	/* Accessible menu */
	$(".main-nav-dd-toggle:has('.container-wrapper')").addClass("has-dropdown");
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .main-nav-link").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".main-nav-dd-toggle").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".main-nav-dd-toggle").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	$(".accesible-navigation-menu").accessibleMenu();

	/* eslint-disable */
	/* Webflow: Interactions: Init */
	Webflow.require('ix').init([
		{slug: "dd-widget-smooth-open",name: "DD Widget Smooth Open",value: {style: {},triggers: [{type: "dropdown",stepsA: [{opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "height 200 ease 0, opacity 200 ease 0"}],stepsB: [{height: "0px",transition: "height 100ms ease 0"}]}]}},
		{slug: "back-to-top-reveal",name: "Back to Top Reveal",value: {style: {},triggers: [{type: "scroll",selector: ".back-to-top-not-working",offsetTop: "20%",offsetBot: "20%",stepsA: [{opacity: 1,transition: "opacity 200 ease 0"}],stepsB: [{opacity: 0,transition: "opacity 200 ease 0"}]}]}}
	]);
	/* eslint-enable */
});

! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);
